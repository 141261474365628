import { defineComponent as _defineComponent } from 'vue'
import ContactUsForm from "@ems/containers/GreenOptions/TheForm/ContactUs.Form.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUs',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { ContactUsForm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})