import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "mb-5 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/setting-information/your-green-options" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Your Green Options ")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, { to: "contact-us" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Contact US")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["ContactUsForm"])
    ])
  ]))
}